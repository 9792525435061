<template>
    <div class="field-table tw-flex-1 tw-flex tw-flex-col tw-gap-y-4 tw-max-w-full tw-text-base-content">
        <div class="tw-rounded tw-rounded-lg tw-border tw-border-solid tw-border-gray-300">
            <div class="tw-rounded tw-rounded-t-lg tw-bg-brand tw-text-white tw-font-semibold tw-px-4 tw-py-2">
                Allocate credits to user
            </div>
            <div class="tw-p-4 tw-flex tw-flex-col tw-gap-y-4">
                <div class="tw-flex tw-items-center tw-w-full tw-gap-4 tw-mb-2">
                    <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                        <label class="tw-text-gray-500">Credit Pack Name</label>
                        <vue-multiselect v-model="creditPack.name" :options="packNames" :disabled="$route.query.id !== 'NEW'" placeholder="Credit Pack Name" :taggable="false" :searchable="true" :show-labels="false" :close-on-select="true" :preserveSearch="true" tagPlaceholder="" @select="resetCreditPack()" @remove="resetCreditPack()"> </vue-multiselect>
                    </div>

                    <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                        <label class="tw-text-gray-500">Credit Pack Unique ID</label>
                        <vue-multiselect v-model="creditPack.display_name" :options="packIds" :disabled="$route.query.id !== 'NEW'" placeholder="Credit Pack ID" :taggable="false" :searchable="true" :show-labels="false" :close-on-select="true" :preserveSearch="true" tagPlaceholder="" @select="updateSelectedCreditPack" @remove="updateSelectedCreditPack()"> </vue-multiselect>
                    </div>
                </div>

                <div class="tw-flex tw-items-center tw-w-full tw-gap-4 tw-mb-2">
                    <div class="tw-flex tw-flex-1 tw-space-x-2 tw-w-1/3">
                        <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                            <label class="tw-text-gray-500">Credits</label>
                            <neo-input class="tw-rounded-lg tw-h-10" placeholder="Credits" bg="var(--brand-accent-neutral)" disabled v-model.trim="creditPack.pack_credits" style="border: 1px solid #e8e8e8;" />
                        </div>

                        <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                            <label class="tw-text-gray-500"> Validity Period </label>
                            <neo-input class="tw-rounded-lg tw-h-10" placeholder="Validity Period" bg="var(--brand-accent-neutral)" disabled v-model.trim="creditPack.validity" style="border: 1px solid #e8e8e8;" />
                        </div>
                    </div>

                    <div class="tw-flex tw-flex-1 tw-space-x-2 tw-w-1/3">
                        <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                            <label class="tw-text-gray-500"> Allocated Credits</label>
                            <neo-input class="tw-rounded-lg tw-h-10" placeholder="Allocated Credits" bg="var(--brand-accent-neutral)" disabled v-model.trim="creditPack.alloted_credits" style="border: 1px solid #e8e8e8;" />
                        </div>

                        <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                            <label class="tw-text-gray-500"> Unallocated Credits </label>
                            <neo-input class="tw-rounded-lg tw-h-10" placeholder="Unallocated Credits" bg="var(--brand-accent-neutral)" disabled v-model.trim="creditPack.remaining_credits" style="border: 1px solid #e8e8e8;" />
                        </div>
                    </div>

                    <div class="tw-flex tw-flex-1 tw-space-x-2 tw-w-1/3">
                        <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                            <label class="tw-text-gray-500"> Total Used Credits </label>
                            <neo-input class="tw-rounded-lg tw-h-10" placeholder="Total Used Credits" bg="var(--brand-accent-neutral)" disabled v-model.trim="creditPack.used" style="border: 1px solid #e8e8e8;" />
                        </div>

                        <div class="tw-flex tw-flex-col tw-flex-1 tw-space-y-2 tw-w-1/2">
                            <label class="tw-text-gray-500"> Total Unused Credits </label>
                            <neo-input class="tw-rounded-lg tw-h-10" placeholder="Total Unused Credits" bg="var(--brand-accent-neutral)" disabled v-model.trim="creditPack.available_credits" style="border: 1px solid #e8e8e8;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-rounded tw-rounded-lg tw-border tw-border-solid tw-border-gray-300">
            <div
                class="tw-rounded tw-rounded-t-lg tw-bg-brand tw-text-white tw-font-semibold tw-px-4 tw-py-2 
                tw-flex tw-justify-between"
            >
                Assigned User

                <div class="tw-flex tw-items-center" :class="creditPack.name && creditPack.display_name ? 'tw-cursor-pointer' : 'tw-cursor-not-allowed'" title="Add User" @click.stop="addUser()">
                    <span
                        class="tw-w-2 tw-h-4 tw-border-2 tw-border-solid tw-border-white tw-relative
                        tw-left-1 tw-border-r-0 tw-rounded-l-md"
                    ></span>
                    <font-awesome-icon icon="plus" color="white" class="add-btn" />
                    <span
                        class="tw-w-2 tw-h-4 tw-border-2 tw-border-solid tw-border-white tw-relative
                        tw-right-1 tw-border-l-0 tw-rounded-r-md"
                    ></span>
                </div>
            </div>
            <div class="tw-p-4 tw-flex tw-flex-col tw-gap-y-4">
                <div class="tw-rounded tw-rounded-lg tw-border tw-border-solid tw-border-gray-300">
                    <div class="header tw-flex tw-justify-around tw-border-solid tw-border-b tw-border-none tw-border-gray-300 tw-px-2 tw-py-1">
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">User Name</div>
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">Credit Limits</div>
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">Allocated Credits</div>
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">Used Credits</div>
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">Unused Credits</div>
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">Action</div>
                    </div>

                    <div class="tw-flex tw-justify-around tw-p-2 tw-gap-2" v-if="!creditPack.users || !creditPack.users.length">
                        <div class="tw-flex tw-mx-auto tw-items-center">
                            Pack not assigned to any user
                        </div>
                    </div>

                    <div class="tw-flex tw-justify-around tw-p-2 tw-gap-2" v-for="(user, index) in creditPack.users" :key="index">
                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">
                            <vue-multiselect v-model="user.user" :options="allUsers" :ref="`user-${index}`" class="var(--area-brand-color)" placeholder="User" :taggable="false" :searchable="true" :close-on-select="true" :clear-on-select="false" :allow-empty="false" :show-labels="false" label="full_name" track-by="id" :preserveSearch="true" tagPlaceholder=""> </vue-multiselect>
                        </div>

                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">
                            <label :for="`user-${index}`" class="tw-flex tw-cursor-pointer tw-items-center ">
                                <input type="checkbox" name="" :id="`user-${index}`" class="tw-w-5 tw-h-5 tw-cursor-pointer tw-mr-2" :checked="user.unlimited_credit_limit" @change="updateUnlimited($event, user)" />
                                All Available Balance
                            </label>
                        </div>

                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">
                            <neo-input class="tw-rounded-lg tw-h-10 tw-w-40" placeholder="Allocated Credits" :id="`credits-${index}`" :disabled="user.unlimited_credit_limit" :bg="user.unlimited_credit_limit ? 'var(--brand-accent-neutral)' : '#fff'" v-model.trim="user.issued_credits" style="border: 1px solid #e8e8e8;" />
                        </div>

                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">
                            <neo-input class="tw-rounded-lg tw-h-10 tw-w-40" placeholder="Used Credits" :id="`credits-${index}`" disabled bg="var(--brand-accent-neutral)" v-model.trim="user.used" style="border: 1px solid #e8e8e8;" />
                        </div>

                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">
                            <neo-input class="tw-rounded-lg tw-h-10 tw-w-40" placeholder="Unused Credits" :id="`credits-${index}`" disabled bg="var(--brand-accent-neutral)" v-model.trim="user.user_available_credits" style="border: 1px solid #e8e8e8;" />
                        </div>

                        <div class="tw-flex tw-flex-1 tw-items-center tw-justify-around">
                            <font-awesome-icon title="Remove User" icon="trash" color="var(--color-danger)" class="remove-btn" @click.stop="deleteUser(user, index)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-flex tw-justify-end">
            <button
                class="tw-py-1 tw-px-3 tw-text-white tw-rounded-lg tw-boder-0 
                tw-flex tw-justify-center tw-items-center tw-font-semibold"
                :class="userDataValid ? 'tw-bg-brand tw-cursor-pointer' : 'tw-bg-gray-300 tw-cursor-not-allowed'"
                :disabled="userDataValid ? false : true"
                @click="saveCreditPack"
            >
                Save
            </button>
        </div>

        <modalConfirm ref="modal_confirm" submitButtonText="Yes" :customFooter="true" @close="userToDelete = null">
            <template #footer>
                <neo-button label="Yes" bg="var(--brand-accent-neutral-alt)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" padding="0.4rem 0.7rem" borderRadius="0.3rem" class="modalContent__footer__button tw-bottom-8 tw-right-8 tw-w-auto" @click="confirmDelete" @keyup.enter="confirmDelete"> </neo-button>

                <neo-button label="No" bg="var(--brand-color)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" padding="0.4rem 0.7rem" borderRadius="0.3rem" class="modalContent__footer__button tw-bottom-8 tw-right-8 tw-w-auto tw-ml-2.5" @click="closeModal" @keyup.enter="closeModal">
                    <neo-loader v-if="loading" class="tw-mr-2.5" :loading="true" :success="success" height="15px" width="15px" />
                </neo-button>
            </template>
        </modalConfirm>
    </div>
</template>
<script>
import dropdown from "@/components/dropdown-base";
import Loader from "@/components/loader";
import NeoInput from "@/components/input";
import Button from "@/components/button";
import modalConfirm from "@/components/modal-confirm";
import VueMultiselect from "@/components/vue-multiselect";
import "@/assets/css/multiselect.scss";
import {getCreditInventory, assignCredits, unassignCredits, getUserFullName} from "../services";
import {cloneDeep, uniq, uniqBy} from "lodash";
import {mapGetters} from "vuex";
import {onlyUnique} from "../../../utils/functions";
import {EventBus} from "@/main.js";

export default {
    name: "credit-allocation",
    title: "Credit Allocation",
    components: {
        dropdown,
        Loader,
        NeoInput,
        "neo-button": Button,
        modalConfirm,
        VueMultiselect,
    },
    props: {
        selected: {
            type: Object,
            default: null,
        },
        allUsers: {
            type: Array,
            default: [],
        },
        tenantCreditPacks: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            creditPack: {
                id: "",
                name: "",
                display_name: "",
                validity: 0,
                pack_credits: 0,
                alloted_credits: 0,
                remaining_credits: 0,
                used: 0,
                available_credits: 0,
                users: [],
            },
            userToDelete: null,
            // removedUsers: [],
        };
    },
    async mounted() {
        this.loading = true;
        this.creditPack = cloneDeep(this.selected);
        this.filterCreditPackUsers();
        this.loading = false;
    },
    watch: {},
    computed: {
        ...mapGetters(["getAccountId"]),

        packNames() {
            return uniq(this.tenantCreditPacks.map((el) => el.name));
        },

        packIds() {
            return this.tenantCreditPacks.filter((el) => el.name === this.creditPack.name).map((el) => el.display_name);
        },

        userDataValid() {
            if (!this.creditPack?.name || !this.creditPack?.display_name || !this.creditPack?.users?.length) return false;
            else {
                let match = -1;
                match = this.creditPack.users.findIndex((user, index) => {
                    return !user.user?.id || (!user.unlimited_credit_limit && !user.issued_credits && user.issued_credits !== "-");
                });
                return match > -1 ? false : true;
            }
        },
    },
    methods: {
        async fetchCreditPack() {
            let qParams;
            if (this.getAccountId)
                qParams = {
                    tenant_id: this.getAccountId,
                };
            qParams = {
                ...qParams,
                ...{credit_pack_id: this.creditPack.id},
            };
            const {data} = await getCreditInventory(qParams);
            this.creditPack = data?.data?.[0];
            this.creditPack.alloted_credits = this.creditPack.pack_credits - this.creditPack.remaining_credits;
            this.filterCreditPackUsers();
            // this.pageData = data?.page;
        },

        updateSelectedCreditPack(event) {
            this.creditPack = {
                ...this.creditPack,
                ...{
                    id: "",
                    validity: 0,
                    alloted_credits: 0,
                    pack_credits: 0,
                    remaining_credits: 0,
                    used: 0,
                    available_credits: 0,
                    users: [],
                },
            };
            this.$forceUpdate();

            const cp = this.tenantCreditPacks.find((el) => el.display_name === event);
            if (cp) {
                this.creditPack = {
                    ...this.creditPack,
                    ...{
                        id: cp.id,
                        validity: cp.validity,
                        alloted_credits: cp.pack_credits - cp.remaining_credits,
                        pack_credits: cp.pack_credits,
                        remaining_credits: cp.remaining_credits,
                        used: cp.used,
                        available_credits: cp.available_credits,
                        users: cp.users,
                    },
                };
                this.filterCreditPackUsers();
            }
        },

        resetCreditPack() {
            this.creditPack = {
                ...this.creditPack,
                ...{
                    id: "",
                    display_name: "",
                    validity: 0,
                    alloted_credits: 0,
                    pack_credits: 0,
                    remaining_credits: 0,
                    used: 0,
                    available_credits: 0,
                    users: [],
                },
            };
        },

        updateUnlimited(event, user) {
            const value = event.target.checked;
            user.unlimited_credit_limit = value;
            if (value) user.issued_credits = "-";
            else user.issued_credits = "";
            this.$forceUpdate();
        },

        filterCreditPackUsers() {
            this.creditPack.users =
                this.creditPack.users
                    ?.filter((el) => el.active)
                    ?.filter(onlyUnique)
                    ?.map((usr) => ({
                        ...usr,
                        user: {
                            full_name: getUserFullName(usr),
                            id: usr.user_id,
                        },
                        issued_credits: usr.unlimited_credit_limit ? "-" : usr.issued_credits,
                    })) || [];
            this.$forceUpdate();
        },

        async saveCreditPack() {
            const users = this.creditPack.users.map((usr) => ({
                user_id: usr.user?.id,
                credits: usr.unlimited_credit_limit ? 0 : parseInt(usr.issued_credits, 10),
                unlimited: usr.unlimited_credit_limit,
            }));
            const uniqueUsers = uniqBy(users, "user_id");
            const duplicateUsers = this.creditPack.users.length > uniqueUsers.length ? true : false;
            if (duplicateUsers) this.$toast.error("Cannot allocate credit pack to same user multiple times!");
            else {
                try {
                    await assignCredits({
                        tenant_id: this.getAccountId,
                        id: this.creditPack.id,
                        assign_to: users,
                    });

                    // this.removedUsers.forEach(async (usr) => {
                    //     await unassignCredits({
                    //         tenant_id: this.getAccountId,
                    //         credit_pack_id: this.creditPack.id,
                    //         user_id: usr,
                    //     });
                    // });
                    this.$toast.success("Credit allocation updated successfully!");
                    EventBus.$emit("refreshCreditPacks");
                    this.fetchCreditPack();
                } catch (error) {
                    console.log(error.response);
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg || error?.response?.data?.status_message || "Something Went Wrong !");
                }
                // this.removedUsers = [];
            }
        },
        addUser() {
            if (this.creditPack?.name && this.creditPack?.display_name) {
                if (!this.creditPack.users) this.creditPack.users = [];

                this.creditPack.users.push({
                    user_id: "",
                    unlimited_credit_limit: false,
                    issued_credits: 0,
                    is_new: true,
                    active: true,
                });
            }
        },

        async deleteUser(user, index) {
            this.userToDelete = {
                user,
                index,
            };
            const confirm_modal = await this.$refs["modal_confirm"].show({
                title: "Are you sure?",
                message: `You are about to delete the user from this pack. This action cannot be Undone.\n \nWould you like to proceed?`,
                // message: `Activation will be done on due date, Expiry Date will be ${expiry_date}`
            });
            this.$refs["modal_confirm"].close();
            if (confirm_modal) this.confirmDelete();
        },

        closeModal() {
            this.$refs["modal_confirm"].close();
            this.userToDelete = null;
        },

        async confirmDelete() {
            const {user, index} = this.userToDelete;
            this.creditPack.users.splice(index, 1);
            if (!user.is_new) {
                // this.removedUsers.push(user.id);
                try {
                    await unassignCredits({
                        tenant_id: this.getAccountId,
                        credit_pack_id: this.creditPack.id,
                        user_id: user.id,
                    });
                    this.$toast.success("Credit allocation updated successfully!");
                    EventBus.$emit("refreshCreditPacks");
                    this.closeModal();
                } catch (error) {
                    console.log(error.response);
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg || error?.response?.data?.status_message || "Something Went Wrong !");
                    this.closeModal();
                }
            } else this.closeModal();
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
.add-btn {
    height: 14px;
}
</style>
