<template>
    <div class="field-table tw-relative tw-flex-1 tw-flex tw-flex-col tw-gap-2 tw-max-w-full tw-text-base-content">
        <template v-if="!creditPackSelected">
            <button
                class="tw-cursor-pointer tw-font-semibold tw-h-10 tw-w-32
                tw-justify-center tw-ml-auto tw-px-3 tw-py-1 tw-rounded-lg tw-bg-brand tw-text-white tw-text-xs"
                @click="allocateNewCredits"
            >
                Allocate Credits
            </button>
            <vue-good-table ref="table" mode="remote" :isLoading="loading" :columns="columns" :rows="rows" :pagination-options="paginationOptions" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" :totalRows="totalRecords">
                <template slot="table-actions">
                    <button
                        v-if="Object.values(filters).length"
                        @click="clearFilters"
                        class="tw-bg-transparent tw-border tw-border-solid tw-rounded-2xl tw-px-2 tw-py-1 tw-mr-2 tw-cursor-pointer 
                        tw-border-brand tw-text-brand hover:tw-bg-brand hover:tw-text-white"
                    >
                        RESET FILTER
                    </button>
                    <span v-else></span>
                </template>

                <template #column-filter="{ column }">
                    <neo-datepicker v-if="column.field_type === 'date' && column.filterOptions && column.filterOptions.enabled" :range="true" v-model="filters[column.field]" :open-date="new Date()" placeholder="Select Date" format="DD-MMM-YYYY" class="tw-h-10 tw-rounded tw-w-full tw-overflow-hidden" ref="datePicker1" @input="updateColumnFilters()" @clear="clearDateRange(column)"> </neo-datepicker>

                    <vue-multiselect ref="setFilterRef" v-else-if="column.filterOptions && column.filterOptions.enabled" placeholder="Select" v-model="filters[column.field]" :options="getFilterOptions(column.filterOptions.key)" @input="updateColumnFilters()" :multiple="false" :checkboxes="false" :limit="1" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :searchable="true" :show-labels="false" class="tw-h-8 tw-w-full"> </vue-multiselect>
                </template>
                <template slot="table-row" slot-scope="props">
                    <!-- Column: Users -->
                    <div v-if="props.column.field === 'users'" class="tw-flex tw-justify-center tw-cursor-pointer" @click="assignedUsers(props.row.users)">
                        <div class="tw-flex">
                            <font-awesome-icon class="tw-w-6 tw-mx-2" icon="user" />
                            <span
                                class="tw-h-4 tw-relative tw--top-2 tw--left-2 
                            tw-rounded tw-rounded-lg tw-border tw-border-blue-600
                            tw-bg-brand tw-text-white tw-px-2"
                            >
                                {{ getActiveUsers(props.row.users).length }}
                            </span>
                        </div>
                    </div>

                    <!-- Column: Unique Id -->
                    <div v-else-if="props.column.field === 'display_name'" @click="selectCreditPack(props.row)" class="tw-flex tw-cursor-pointer tw-text-brand">
                        {{ props.row[props.column.field] }}
                    </div>

                    <!-- Column: Status -->
                    <div v-else-if="props.column.field === 'status'" class="tw-flex tw-justify-center">
                        <span :class="['Allocated', 'Active'].includes(props.row.status) ? 'tw-text-success' : 'tw-text-danger'">
                            {{ props.row.status }}
                        </span>
                    </div>

                    <!-- Date type field -->
                    <div v-else-if="props.column.field_type === 'date'" class="tw-flex tw-justify-center">
                        <span v-if="props.row[props.column.field]">
                            {{ formatDateFromISO(props.row[props.column.field], "dd MMM yyyy") }}
                        </span>
                        <span v-else> - </span>
                    </div>

                    <!-- Default -->
                    <span v-else class="tw-flex tw-justify-center"> {{ props.row[props.column.field] }} </span>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
        </template>

        <!-- Create / Modify Credit allocation -->
        <template v-else>
            <allocate-credits :selected="creditPackSelected" :allUsers="allUsers" :tenantCreditPacks="rows" />
        </template>
        <Modal ref="assigned-users" title="Assigned Users" :showFooter="false">
            <template #content>
                <div class="tw-grid tw-gap-2">
                    <neo-input class="tw-rounded-lg tw-h-10" placeholder="Search" bg="#fff" v-model.trim="search" style="border: 1px solid #e8e8e8;" />

                    <span class="tw-border-0 tw-border-solid tw-border-b tw-border-gray-200 tw-text-bold tw-pb-2" v-for="data in filteredUserLists" :key="data.user_id">
                        {{ data.user_name }}
                    </span>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from "@/components/modal-content";
import dropdown from "@/components/dropdown-base";
import NeoInput from "@/components/input";
import Loader from "@/components/loader";
const VueMultiselect = () => import("@/components/vue-multiselect");
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import allocateCredits from "./allocate-credits";
import {DateTime} from "luxon";
import {getCreditInventory, getAllUsers, getUserFullName} from "../services";
import {mapGetters} from "vuex";
import {onlyUnique} from "@/utils/functions.js";

export default {
    name: "credit-allocation",
    title: "Credit Allocation",
    components: {
        Modal,
        dropdown,
        NeoInput,
        Loader,
        allocateCredits,
        "neo-datepicker": DatePicker,
        VueMultiselect,
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    label: "Pack Name",
                    field: "name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "credit_pack_names",
                    },
                },
                {
                    label: "Unique Id",
                    field: "display_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "display_name",
                        key: "credit_pack_display_names",
                    },
                },
                {
                    label: "Credits",
                    field: "pack_credits",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "credit_pack_credits",
                    },
                },
                {
                    label: "Validity Period",
                    field: "validity",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "credit_pack_validity",
                    },
                },
                {
                    label: "Start Date",
                    field: "activation_due_date",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Expiry Date",
                    field: "valid_till",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Assigned User",
                    field: "users",
                    sortable: false,
                    filterOptions: {
                        enabled: true,
                        key: "user_name",
                    },
                },
                {
                    label: "Allotted",
                    field: "alloted_credits",
                    sortable: false,
                    filterOptions: {
                        enabled: false,
                    },
                },
                {
                    label: "Balance",
                    field: "remaining_credits",
                    sortable: false,
                    filterOptions: {
                        enabled: false,
                    },
                },
                {
                    label: "Pack Status",
                    field: "status",
                    sortable: false,
                    filterOptions: {
                        enabled: false,
                    },
                },
            ],
            rows: [],
            totalRecords: 0,
            filters: {},
            pagination: {
                limit: 10,
                page: 1,
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
                infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords}`,
                perPageDropdown: [10, 20, 30, 40, 50],
                dropdownAllowAll: false,
            },
            userLists: [],
            allUsers: [],
            creditPackSelected: null,
            search: "",
        };
    },
    async mounted() {
        this.loading = true;
        await this.fetchData();
        if (this.getAccountId) this.allUsers = await getAllUsers(this.getAccountId);
        this.loading = false;
    },
    watch: {
        async "$route.query"(to, from) {
            if (!to.id) this.creditPackSelected = null;
            this.clearFilters();
        },
    },
    computed: {
        ...mapGetters(["getAccountId", "getFiltersData"]),
        filteredUserLists() {
            return this.userLists.filter((el) => el.user_name.toLowerCase().includes(this.search.toLowerCase()));
        },
    },
    methods: {
        disabledRange(date) {
            return date > new Date();
        },

        async clearDateRange(column) {
            this.filters[column.field] = [];
            await this.fetchData();
        },

        allocateNewCredits() {
            this.creditPackSelected = {};
            this.$router
                .push({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        ...{id: "NEW"},
                    },
                })
                .catch(() => {});
            this.$emit("updateBreadcrumbs");
        },

        async selectCreditPack(row) {
            this.creditPackSelected = row;
            this.$router
                .push({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        ...{id: this.creditPackSelected.id},
                    },
                })
                .catch(() => {});
            this.$emit("updateBreadcrumbs");
            await this.fetchData();
        },

        async fetchData() {
            this.rows = [];
            let qParams = {
                ...this.pagination,
            };

            const dateFields = this.columns.filter((col) => col.field_type === "date")?.map((col) => col.field);

            for (const filter in this.filters) {
                if (dateFields.includes(filter) && this.filters[filter]?.length) {
                    let key = filter;

                    const start = new Date(this.filters[filter][0]);
                    const end = new Date(this.filters[filter][1]);
                    qParams = {
                        ...qParams,
                        [`${key}_from`]:
                            start.getFullYear() +
                            "-" +
                            (start.getMonth() + 1).toString().padStart(2, "0") +
                            "-" +
                            start
                                .getDate()
                                .toString()
                                .padStart(2, "0"),
                        [`${key}_to`]:
                            end.getFullYear() +
                            "-" +
                            (end.getMonth() + 1).toString().padStart(2, "0") +
                            "-" +
                            end
                                .getDate()
                                .toString()
                                .padStart(2, "0"),
                    };
                } else if (filter === "name")
                    qParams = {
                        ...qParams,
                        ["pack_name"]: this.filters[filter],
                    };
                else if (filter === "users")
                    qParams = {
                        ...qParams,
                        ["user_name"]: this.filters[filter],
                    };
                else
                    qParams = {
                        ...qParams,
                        [filter]: this.filters[filter],
                    };
            }

            if (this.getAccountId)
                qParams = {
                    ...qParams,
                    tenant_id: this.getAccountId,
                };
            if (this.creditPackSelected)
                qParams = {
                    ...qParams,
                    ...{credit_pack_id: this.creditPackSelected.id},
                };
            const {data} = await getCreditInventory(qParams);
            this.rows = data.data.map((el) => ({
                ...el,
                ...{alloted_credits: el.pack_credits - el.remaining_credits},
            }));
            if (data?.page?.total) {
                this.totalRecords = data.page.total;
            } else {
                this.totalRecords = 0;
            }
            // this.pageData = data?.page;
        },

        formatDateFromISO(dt, format = "EEE, MMM dd yyyy") {
            dt = dt.substr(0, 10);
            return DateTime.fromISO(dt).toFormat(format);
        },

        getFilterOptions(key) {
            // get unique filter options array
            return this.getFiltersData?.[key].filter((val) => val) ?? [];
        },
        async updateColumnFilters() {
            this.pagination = {
                ...this.pagination,
                page: 1,
            };
            this.$refs["table"].reset();
            await this.fetchData();
        },
        async clearFilters() {
            this.filters = {};
            this.pagination = {
                limit: 10,
                page: 1,
            };
            this.$refs["table"].reset();
            await this.fetchData();
        },

        async onPageChange(params) {
            this.pagination = {
                ...this.pagination,
                page: params.currentPage,
            };
            await this.fetchData();
        },

        async onPerPageChange(params) {
            this.pagination = {
                ...this.pagination,
                limit: params.currentPerPage,
                page: 1,
            };
            await this.fetchData();
        },

        async onSortChange(params) {
            let sort_key = params[0].field;
            if (sort_key === "name") sort_key = "pack_name";
            else if (sort_key === "users") sort_key = "user_name";
            else if (sort_key === "created_at") sort_key = "credit_allocation_date";
            if (params[0].type == "none") {
                this.$refs["my-table"].sorts[0].type = "asc";
                this.pagination = {
                    ...this.pagination,
                    sort_on: sort_key,
                    sort_value: "asc",
                    page: 1,
                };
            } else {
                this.pagination = {
                    ...this.pagination,
                    sort_on: sort_key,
                    sort_value: params[0].type,
                    page: 1,
                };
            }
            await this.fetchData();
        },

        getActiveUsers(users) {
            return users.filter((el) => el.active).filter(onlyUnique);
        },

        assignedUsers(users) {
            const active_users = this.getActiveUsers(users);
            if (active_users.length) {
                this.userLists = active_users.map((user) => ({
                    ...user,
                    ...{user_name: getUserFullName(user)},
                }));
                this.search = "";
                this.$refs["assigned-users"].showModal();
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
@include tableComponent;
</style>
