<template>
    <div class="field-table tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-full tw-text-base-content">
        <vue-good-table ref="table" mode="remote" :isLoading="loading" :columns="columns" :rows="rows" :pagination-options="paginationOptions" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" :totalRows="totalRecords">
            <template slot="table-actions">
                <button
                    v-if="Object.values(filters).length"
                    @click="clearFilters"
                    class="tw-bg-transparent tw-border tw-border-solid tw-rounded-2xl tw-px-2 tw-py-1 tw-mr-2 tw-cursor-pointer 
                    tw-border-brand tw-text-brand hover:tw-bg-brand hover:tw-text-white"
                >
                    RESET FILTER
                </button>
                <span v-else></span>
            </template>

            <template #column-filter="{ column }">
                <neo-datepicker v-if="column.field_type === 'date' && column.filterOptions && column.filterOptions.enabled" :range="true" v-model="filters[column.field]" :open-date="new Date()" placeholder="Select Date" format="DD-MMM-YYYY" class="tw-h-10 tw-rounded tw-w-full tw-overflow-hidden" ref="datePicker1" @input="updateColumnFilters()" @clear="clearDateRange(column)"> </neo-datepicker>

                <vue-multiselect ref="setFilterRef" v-else-if="column.filterOptions && column.filterOptions.enabled" placeholder="Select" v-model="filters[column.field]" :options="getFilterOptions(column.filterOptions.key)" @input="updateColumnFilters()" :multiple="false" :checkboxes="false" :limit="1" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :searchable="true" :show-labels="false" class="tw-h-8 tw-w-full"> </vue-multiselect>
            </template>
            <template slot="table-row" slot-scope="props">
                {{ props.row[props.column.field] }}
            </template>

            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
import Modal from "@/components/modal-content";
import dropdown from "@/components/dropdown-base";
import Loader from "@/components/loader";
const VueMultiselect = () => import("@/components/vue-multiselect");
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {getCreditFeatures} from "../services";
import {mapGetters} from "vuex";
import {DateTime} from "luxon";

export default {
    name: "feature",
    title: "Feature",
    components: {
        Modal,
        dropdown,
        Loader,
        "neo-datepicker": DatePicker,
        VueMultiselect,
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    label: "Feature",
                    field: "display_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "feature_name",
                    },
                },
                {
                    label: "Input",
                    field: "input",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "feature_input_values",
                    },
                },
                {
                    label: "Output",
                    field: "output",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "feature_output_values",
                    },
                },
                {
                    label: "Credit/Hits",
                    field: "credit_per_hit",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "feature_credit_per_hit",
                    },
                },
            ],
            rows: [],
            totalRecords: 0,
            filters: {},
            pagination: {
                limit: 10,
                page: 1,
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
                infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords}`,
                perPageDropdown: [10, 20, 30, 40, 50],
                dropdownAllowAll: false,
            },
        };
    },
    async mounted() {
        this.loading = true;
        await this.fetchData();
        this.loading = false;
    },
    computed: {
        ...mapGetters(["getAccountId", "getFiltersData"]),
    },
    methods: {
        disabledRange(date) {
            return date > new Date();
        },

        async clearDateRange(column) {
            this.filters[column.field] = [];
            await this.fetchData();
        },

        async fetchData() {
            let qParams = {
                ...this.pagination,
            };

            const dateFields = this.columns.filter((col) => col.field_type === "date")?.map((col) => col.field);

            for (const filter in this.filters) {
                if (dateFields.includes(filter) && this.filters[filter]?.length) {
                    let key = filter;

                    const start = new Date(this.filters[filter][0]);
                    const end = new Date(this.filters[filter][1]);
                    qParams = {
                        ...qParams,
                        [`${key}_from`]:
                            start.getFullYear() +
                            "-" +
                            (start.getMonth() + 1).toString().padStart(2, "0") +
                            "-" +
                            start
                                .getDate()
                                .toString()
                                .padStart(2, "0"),
                        [`${key}_to`]:
                            end.getFullYear() +
                            "-" +
                            (end.getMonth() + 1).toString().padStart(2, "0") +
                            "-" +
                            end
                                .getDate()
                                .toString()
                                .padStart(2, "0"),
                    };
                } else
                    qParams = {
                        ...qParams,
                        [filter]: this.filters[filter],
                    };
            }

            if (this.getAccountId)
                qParams = {
                    ...qParams,
                    tenant_id: this.getAccountId,
                };

            const {data} = await getCreditFeatures(qParams);
            this.rows = data.data;
            if (data?.page?.total) {
                this.totalRecords = data.page.total;
            } else {
                this.totalRecords = 0;
            }
        },

        formatDateFromISO(dt, format = "EEE, MMM dd yyyy") {
            dt = dt.substr(0, 10);
            return DateTime.fromISO(dt).toFormat(format);
        },

        getFilterOptions(key) {
            // get unique filter options array
            return this.getFiltersData?.[key].filter((val) => val) ?? [];
        },
        async updateColumnFilters() {
            this.pagination = {
                ...this.pagination,
                page: 1,
            };
            this.$refs["table"].reset();
            await this.fetchData();
        },
        async clearFilters() {
            this.filters = {};
            this.pagination = {
                limit: 10,
                page: 1,
            };
            this.$refs["table"].reset();
            await this.fetchData();
        },

        async onPageChange(params) {
            this.pagination = {
                ...this.pagination,
                page: params.currentPage,
            };
            await this.fetchData();
        },

        async onPerPageChange(params) {
            this.pagination = {
                ...this.pagination,
                limit: params.currentPerPage,
                page: 1,
            };
            await this.fetchData();
        },

        async onSortChange(params) {
            if (params[0].type == "none") {
                this.$refs["my-table"].sorts[0].type = "asc";
                this.pagination = {
                    ...this.pagination,
                    sort_on: params[0].field,
                    sort_value: "asc",
                    page: 1,
                };
            } else {
                this.pagination = {
                    ...this.pagination,
                    sort_on: params[0].field,
                    sort_value: params[0].type,
                    page: 1,
                };
            }
            await this.fetchData();
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
@include tableComponent;
</style>
